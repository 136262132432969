var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      style: _vm.modalStyle,
      attrs: {
        width: _vm.modalWidth,
        visible: _vm.visible,
        maskClosable: false,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [_c("a-button", { on: { click: _vm.handleCancel } }, [_vm._v("关闭")])],
        1
      ),
      _c("a-table", {
        ref: "table",
        attrs: {
          rowKey: "id",
          size: "middle",
          columns: _vm.columns,
          loading: _vm.loading,
          dataSource: _vm.dataSource,
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleBack(record.id)
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "redo" } }),
                      _vm._v("字典取回"),
                    ],
                    1
                  ),
                  _c("a-divider", { attrs: { type: "vertical" } }),
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(record.id)
                        },
                      },
                    },
                    [
                      _c("a-icon", { attrs: { type: "scissor" } }),
                      _vm._v("彻底删除"),
                    ],
                    1
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }